<template>
  <svg
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 512 512"
    style="enable-background:new 0 0 512 512;"
    xml:space="preserve"
  >
    <rect
      x="365.714"
      y="82.286"
      style="fill:#5B5D6E;"
      width="36.571"
      height="73.143"
    />
    <rect
      x="365.714"
      y="82.286"
      style="fill:#464655;"
      width="36.571"
      height="27.429"
    />
    <path
      style="fill:#D5DCED;"
      d="M466.286,146.286H45.714c-10.099,0-18.286,8.186-18.286,18.286v246.857h457.143V164.571
	C484.571,154.472,476.385,146.286,466.286,146.286z"
    />
    <path
      style="fill:#B4E66E;"
      d="M438.857,228.571H265.143c-5.049,0-9.143-4.094-9.143-9.143V192c0-5.049,4.094-9.143,9.143-9.143
	h173.714c5.049,0,9.143,4.094,9.143,9.143v27.429C448,224.478,443.906,228.571,438.857,228.571z"
    />
    <path
      style="fill:#C7CFE2;"
      d="M91.429,146.286H45.714c-10.098,0-18.286,8.186-18.286,18.286v246.857h45.714V164.571
	C73.143,154.472,81.33,146.286,91.429,146.286z"
    />
    <path
      style="fill:#707487;"
      d="M493.714,512H18.286c-5.049,0-9.143-4.094-9.143-9.143v-91.429c0-5.049,4.094-9.143,9.143-9.143
	h475.429c5.049,0,9.143,4.094,9.143,9.143v91.429C502.857,507.906,498.763,512,493.714,512z"
    />
    <circle style="fill:#C7CFE2;" cx="256" cy="457.143" r="22.857" />
    <path
      style="fill:#707487;"
      d="M301.714,91.429h164.571c10.099,0,18.286-8.186,18.286-18.286V18.286
	C484.571,8.186,476.385,0,466.286,0H301.714c-10.099,0-18.286,8.186-18.286,18.286v54.857
	C283.429,83.242,291.615,91.429,301.714,91.429z"
    />
    <path
      style="fill:#B4E66E;"
      d="M457.143,73.143H310.857c-5.049,0-9.143-4.094-9.143-9.143V27.429c0-5.049,4.094-9.143,9.143-9.143
	h146.286c5.049,0,9.143,4.094,9.143,9.143V64C466.286,69.049,462.192,73.143,457.143,73.143z"
    />
    <g>
      <path
        style="fill:#959CB5;"
        d="M283.429,301.714h-9.143c-10.099,0-18.286-8.186-18.286-18.286l0,0
		c0-10.099,8.186-18.286,18.286-18.286h9.143c10.099,0,18.286,8.186,18.286,18.286l0,0
		C301.714,293.528,293.528,301.714,283.429,301.714z"
      />
      <path
        style="fill:#959CB5;"
        d="M356.571,301.714h-9.143c-10.099,0-18.286-8.186-18.286-18.286l0,0
		c0-10.099,8.186-18.286,18.286-18.286h9.143c10.099,0,18.286,8.186,18.286,18.286l0,0
		C374.857,293.528,366.671,301.714,356.571,301.714z"
      />
    </g>
    <path
      style="fill:#FF507D;"
      d="M429.714,301.714h-9.143c-10.099,0-18.286-8.186-18.286-18.286l0,0
	c0-10.099,8.186-18.286,18.286-18.286h9.143c10.099,0,18.286,8.186,18.286,18.286l0,0C448,293.528,439.814,301.714,429.714,301.714z
	"
    />
    <path
      style="fill:#959CB5;"
      d="M283.429,365.714h-9.143c-10.099,0-18.286-8.186-18.286-18.286l0,0
	c0-10.099,8.186-18.286,18.286-18.286h9.143c10.099,0,18.286,8.186,18.286,18.286l0,0
	C301.714,357.528,293.528,365.714,283.429,365.714z"
    />
    <g>
      <path
        style="fill:#7F8499;"
        d="M182.857,301.714h-9.143c-10.099,0-18.286-8.186-18.286-18.286l0,0
		c0-10.099,8.186-18.286,18.286-18.286h9.143c10.099,0,18.286,8.186,18.286,18.286l0,0
		C201.143,293.528,192.957,301.714,182.857,301.714z"
      />
      <path
        style="fill:#7F8499;"
        d="M182.857,365.714h-9.143c-10.099,0-18.286-8.186-18.286-18.286l0,0
		c0-10.099,8.186-18.286,18.286-18.286h9.143c10.099,0,18.286,8.186,18.286,18.286l0,0
		C201.143,357.528,192.957,365.714,182.857,365.714z"
      />
      <path
        style="fill:#7F8499;"
        d="M109.714,301.714h-9.143c-10.099,0-18.286-8.186-18.286-18.286l0,0
		c0-10.099,8.186-18.286,18.286-18.286h9.143c10.099,0,18.286,8.186,18.286,18.286l0,0C128,293.528,119.814,301.714,109.714,301.714
		z"
      />
      <path
        style="fill:#7F8499;"
        d="M109.714,365.714h-9.143c-10.099,0-18.286-8.186-18.286-18.286l0,0
		c0-10.099,8.186-18.286,18.286-18.286h9.143c10.099,0,18.286,8.186,18.286,18.286l0,0C128,357.528,119.814,365.714,109.714,365.714
		z"
      />
    </g>
    <path
      style="fill:#959CB5;"
      d="M356.571,365.714h-9.143c-10.099,0-18.286-8.186-18.286-18.286l0,0
	c0-10.099,8.186-18.286,18.286-18.286h9.143c10.099,0,18.286,8.186,18.286,18.286l0,0
	C374.857,357.528,366.671,365.714,356.571,365.714z"
    />
    <g>
      <path
        style="fill:#C8FF82;"
        d="M429.714,365.714h-9.143c-10.099,0-18.286-8.186-18.286-18.286l0,0
		c0-10.099,8.186-18.286,18.286-18.286h9.143c10.099,0,18.286,8.186,18.286,18.286l0,0C448,357.528,439.814,365.714,429.714,365.714
		z"
      />
      <path
        style="fill:#C8FF82;"
        d="M338.286,54.857h-9.143c-5.054,0-9.143-4.094-9.143-9.143s4.089-9.143,9.143-9.143h9.143
		c5.054,0,9.143,4.094,9.143,9.143S343.339,54.857,338.286,54.857z"
      />
      <path
        style="fill:#C8FF82;"
        d="M438.857,54.857h-9.143c-5.054,0-9.143-4.094-9.143-9.143s4.089-9.143,9.143-9.143h9.143
		c5.054,0,9.143,4.094,9.143,9.143S443.911,54.857,438.857,54.857z"
      />
      <path
        style="fill:#C8FF82;"
        d="M388.571,54.857h-9.143c-5.054,0-9.143-4.094-9.143-9.143s4.089-9.143,9.143-9.143h9.143
		c5.054,0,9.143,4.094,9.143,9.143S393.625,54.857,388.571,54.857z"
      />
    </g>
    <circle style="fill:#959CB5;" cx="256" cy="457.143" r="9.143" />
    <g>
      <path
        style="fill:#5B5D6E;"
        d="M109.714,493.714c-30.297,0-54.857-24.56-54.857-54.857v-36.571H18.286
		c-5.049,0-9.143,4.094-9.143,9.143v91.429c0,5.049,4.094,9.143,9.143,9.143h475.429c5.049,0,9.143-4.094,9.143-9.143v-9.143
		H109.714z"
      />
      <path
        style="fill:#5B5D6E;"
        d="M210.286,210.286H64c-5.049,0-9.143-4.094-9.143-9.143l0,0c0-5.049,4.094-9.143,9.143-9.143h146.286
		c5.049,0,9.143,4.094,9.143,9.143l0,0C219.429,206.192,215.335,210.286,210.286,210.286z"
      />
    </g>
    <polygon
      style="fill:#E4EAF8;"
      points="179.809,73.143 158.477,64 137.143,73.143 115.81,64 94.478,73.143 73.144,64 
	73.144,210.286 201.143,210.286 201.143,64 "
    />
    <g>
      <path
        style="fill:#7F8499;"
        d="M164.571,173.714h-54.857c-5.054,0-9.143-4.089-9.143-9.143s4.089-9.143,9.143-9.143h54.857
		c5.054,0,9.143,4.089,9.143,9.143S169.625,173.714,164.571,173.714z"
      />
      <path
        style="fill:#7F8499;"
        d="M164.571,137.143h-9.134c-5.054,0-9.143-4.094-9.143-9.143c0-5.049,4.089-9.143,9.143-9.143h9.134
		c5.054,0,9.143,4.094,9.143,9.143C173.714,133.049,169.625,137.143,164.571,137.143z"
      />
    </g>
    <path
      style="fill:#707487;"
      d="M118.857,137.143h-9.143c-5.054,0-9.143-4.094-9.143-9.143c0-5.049,4.089-9.143,9.143-9.143h9.143
	c5.054,0,9.143,4.094,9.143,9.143C128,133.049,123.911,137.143,118.857,137.143z"
    />
    <rect
      x="73.143"
      y="192"
      style="fill:#C7CFE2;"
      width="128"
      height="18.286"
    />
    <g>
      <path
        style="fill:#C8FF82;"
        d="M304.558,215.165l14.086-21.129c0.848-1.272,2.275-2.035,3.803-2.035h38.143
		c2.191,0,3.497,2.441,2.282,4.264l-14.086,21.129c-0.848,1.272-2.275,2.035-3.803,2.035H306.84
		C304.649,219.429,303.342,216.987,304.558,215.165z"
      />
      <path
        style="fill:#C8FF82;"
        d="M368.558,215.165l14.086-21.129c0.848-1.272,2.275-2.035,3.803-2.035h19.857
		c2.191,0,3.497,2.441,2.282,4.264l-14.086,21.129c-0.848,1.272-2.275,2.035-3.803,2.035H370.84
		C368.649,219.429,367.342,216.987,368.558,215.165z"
      />
    </g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
  </svg>
</template>

<script>
export default {};
</script>

<style></style>
